import { getProcessorsByUser, deleteQuarterlyProduction, postQuarterlyProduction, getQuarterlyProduction2, putQuarterlyProduction } from '@/api/dataentry-api-generated';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SaveDialog from '@/components/SaveDialog.vue';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'QuartlyProductionComponent',
    components: { DeleteDialog, SaveDialog },
    props: {
        propSelected: Array,
        propCreateNew: Boolean,
        propDuplicateRecord: Boolean,
        propId: String
    },
    data: function () {
        return {
            filter: '',
            effectiveDates: '',
            createNew: this.propCreateNew,
            duplicateRecord: this.propDuplicateRecord,
            id: this.propId,
            loading: false,
            processors: [],
            item: {
                mondayMorningShiftForHarvestSlaughter: false,
                tuesdayMorningShiftForHarvestSlaughter: false,
                wednesdayMorningShiftForHarvestSlaughter: false,
                thursdayMorningShiftForHarvestSlaughter: false,
                fridayMorningShiftForHarvestSlaughter: false,
                saturdayMorningShiftForHarvestSlaughter: false,
                sundayMorningShiftForHarvestSlaughter: false,
                mondayAfternoonShiftForHarvestSlaughter: false,
                tuesdayAfternoonShiftForHarvestSlaughter: false,
                wednesdayAfternoonShiftForHarvestSlaughter: false,
                thursdayAfternoonShiftForHarvestSlaughter: false,
                fridayAfternoonShiftForHarvestSlaughter: false,
                saturdayAfternoonShiftForHarvestSlaughter: false,
                sundayAfternoonShiftForHarvestSlaughter: false,
                mondayEveningShiftForHarvestSlaughter: false,
                tuesdayEveningShiftForHarvestSlaughter: false,
                wednesdayEveningShiftForHarvestSlaughter: false,
                thursdayEveningShiftForHarvestSlaughter: false,
                fridayEveningShiftForHarvestSlaughter: false,
                saturdayEveningShiftForHarvestSlaughter: false,
                sundayEveningShiftForHarvestSlaughter: false,
                mondayMorningShiftForFabricationBoningRoom: false,
                tuesdayMorningShiftForFabricationBoningRoom: false,
                wednesdayMorningShiftForFabricationBoningRoom: false,
                thursdayMorningShiftForFabricationBoningRoom: false,
                fridayMorningShiftForFabricationBoningRoom: false,
                saturdayMorningShiftForFabricationBoningRoom: false,
                sundayMorningShiftForFabricationBoningRoom: false,
                mondayAfternoonShiftForFabricationBoningRoom: false,
                tuesdayAfternoonShiftForFabricationBoningRoom: false,
                wednesdayAfternoonShiftForFabricationBoningRoom: false,
                thursdayAfternoonShiftForFabricationBoningRoom: false,
                fridayAfternoonShiftForFabricationBoningRoom: false,
                saturdayAfternoonShiftForFabricationBoningRoom: false,
                sundayAfternoonShiftForFabricationBoningRoom: false,
                mondayEveningShiftForFabricationBoningRoom: false,
                tuesdayEveningShiftForFabricationBoningRoom: false,
                wednesdayEveningShiftForFabricationBoningRoom: false,
                thursdayEveningShiftForFabricationBoningRoom: false,
                fridayEveningShiftForFabricationBoningRoom: false,
                saturdayEveningShiftForFabricationBoningRoom: false,
                sundayEveningShiftForFabricationBoningRoom: false
            },
            speciesOptions: ['BOVINE', 'OVINE', 'PORCINE', 'CAPRINE', 'CALVES', 'LAMBS', 'OTHER'],
            processorOptions: [{
                    label: '',
                    value: ''
                }],
            processorDisplay: {
                label: '',
                value: ''
            },
            tablePagination: {
                sortBy: 'site',
                descending: true,
                rowsPerPage: 50
            },
            financialYearQuarterOptions: [
                { value: 'Q1', label: 'Q1 (Jul 1 - Sep 30)' },
                { value: 'Q2', label: 'Q2 (Oct 1 - Dec 31)' },
                { value: 'Q3', label: 'Q3 (Jan 1  - Mar 31)' },
                { value: 'Q4', label: 'Q4 (Apr 1 - Jun 30)' }
            ]
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        selectedItems() {
            return this.propSelected;
        },
        buttonLabel() {
            return 'SAVE';
        },
        years() {
            const currentYear = new Date().getFullYear();
            const currentDate = new Date();
            // Set the financial year start date to July 1st
            const financialYearStartDate = new Date(currentYear, 6, 1);
            // Generate values from the past 20 years until the current year
            let yearOptions = Array.from({ length: 21 }, (_, i) => `${currentYear - i} (Jul 1 ${(currentYear - i) - 1} - Jun 30 ${currentYear - i})`);
            // Add an option for the 2025 financial year if July 1st has already passed
            if (currentDate > financialYearStartDate) {
                yearOptions.unshift(`${currentYear + 1} (Jul 1 ${currentYear} - Jun 30 ${currentYear + 1})`);
            }
            return yearOptions;
        },
        shouldShowField() {
            return this.item.processedSpecies === 'BOVINE' ||
                this.item.processedSpecies === 'CALVES' ||
                this.item.processedSpecies === 'LAMBS' ||
                this.item.processedSpecies === 'OTHER';
        },
        shouldShowFieldIfBovine() {
            return this.item.processedSpecies === 'BOVINE' || this.item.processedSpecies === 'CALVES';
        },
        computedTransition() {
            return 'fade';
        }
    },
    created() {
        this.getProcessors();
        if (!this.createNew) {
            this.getItem(this.uuid, true);
        }
        if (this.duplicateRecord) {
            if (this.id !== undefined) {
                this.getItem(this.id, false);
            }
        }
    },
    methods: {
        async getItem(uuid, isUpdate) {
            this.loading = true;
            try {
                const result = await getQuarterlyProduction2(uuid);
                this.item = { ...result };
                if (isUpdate) {
                    this.item.yearEntry = result.yearEntry;
                    this.processorDisplay.label = result.qrtProductionProcessorLabel;
                    this.processorDisplay.value = result.processor;
                }
                else {
                    this.item.yearEntry = new Date().getFullYear();
                }
                this.loading = false;
            }
            catch (error) {
                const errMsg = error;
                if (errMsg.message.includes('ResourceNotFoundException')) {
                    this.closeDialog();
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        saveItem() {
            if (!this.createNew) {
                this.updateItem();
            }
            else {
                this.createItem();
            }
        },
        async updateItem() {
            // Validate the form before proceeding
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                req.yearEntry = this.item.yearEntry;
                try {
                    const req = this.item;
                    req.processor = this.processorDisplay.value;
                    await putQuarterlyProduction(this.uuid, this.item);
                    this.$log.addMessage('Successfully updated Quarterly Product');
                    this.$router.push({ name: 'Quartile Productions' });
                }
                catch (error) {
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        async createItem() {
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                req.yearEntry = this.item.yearEntry;
                req.processor = this.processorDisplay.value;
                req.processedSpecies = this.item.processedSpecies;
                try {
                    await postQuarterlyProduction(req);
                    this.$log.addMessage('Successfully created new Quarterly Product');
                    this.$emit('refresh');
                    this.closeDialog();
                }
                catch (error) {
                    const errorObj = error;
                    if (errorObj.message.includes('ResourceAlreadyExistsException')) {
                        const uuid = errorObj.message.substring(31, errorObj.message.length);
                        const msg = `A record with the same Quarterly Product already exists: <a href="#/QuarterlyProduction/${uuid}">VIEW</a>`;
                        const errorMsg = {
                            type: 'warning',
                            textColor: 'black',
                            timeout: 5000,
                            position: 'top',
                            message: msg,
                            html: true
                        };
                        this.$log.addCustomMessage(errorMsg);
                    }
                    else {
                        this.$log.addError(error);
                    }
                    this.loading = false;
                }
            }
        },
        async deleteItem() {
            try {
                const result = await deleteQuarterlyProduction(this.uuid);
                this.item = result;
                this.$log.addMessage('Successfully deleted Quarterly Product');
                this.closeDialog();
            }
            catch (error) {
                this.$log.addError(error);
            }
        },
        closeDialog() {
            if (this.createNew) {
                this.$emit('show-hide-dialog');
            }
            else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push({ name: 'Quartile Productions' });
        },
        async getProcessors() {
            this.loading = true;
            try {
                const result = await getProcessorsByUser({ pageSize: 1000000, deleted: false });
                this.processors = result.items;
                this.processorOptions = this.getProcessorOptions();
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        getProcessorOptions() {
            // Display and sort the options in alphabetical order
            return this.processors.map(i => {
                return {
                    label: i.processorLabel,
                    value: i.uuid
                };
            }).sort((a, b) => a.label.localeCompare(b.label));
        },
        filterOptions(val, update) {
            if (val === '') {
                update(() => {
                    // No filter - display default list
                    this.processorOptions = this.getProcessorOptions();
                });
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                // Sort the search results in alphabetical order
                this.processorOptions = this.getProcessorOptions()
                    .filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                    .sort((a, b) => a.label.localeCompare(b.label));
            });
        }
    }
});
